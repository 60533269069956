import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Dialog, Slide, DialogTitle, DialogContent, CardMedia, Tooltip, useMediaQuery, Divider, Chip, IconButton, Grid, Stack, Box, Button, colors, Card, CardHeader, CardContent, TextField, InputLabel } from "@mui/material";
import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";

import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import _ from 'lodash'
import Calendar from "./Calander";
import RegisterForm from "./RegisterForm";

import { IoMdCloseCircleOutline } from "react-icons/io";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Margin } from "@mui/icons-material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuCalendarOff } from "react-icons/lu";
import { AiTwotoneClockCircle } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import DocumentSection from "./Documents";
import PaymentPage from "./PaymentPage";
import dayjs from 'dayjs';
import DigitalOcean from "./DigitalOcean";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MdLocalOffer } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import UploadImage from "./UploadImage";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});



const NewUserRegister = ({ setRegisterStatus, openStatus, data, finalData, classmode }) => {

  console.log('praveen', classmode && classmode)



  const navigate = useNavigate()

  console.log('finalData', finalData)
  const [open, setOpen] = useState(false);


  const [CourseInfo, setCourseInfo] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: data
      })
      if (response.data.message) {
        console.log('response', response?.data?.response)
        setCourseInfo(response?.data?.response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [data])


  const [coursedetails, setCoursedetails] = useState([])

  useEffect(() => {
    const formating = finalData?.packages?.map((item) => {
      return {
        productid: item.productid,
        productname: item.productname,
        packageid: item.packageid,
        type: item.producttype,
        addonid: null,
        classmode: classmode,
        locationid: '',
        packageamount: item?.packageamount,
        comboid: finalData?.comboid,

      }
    })
    setCoursedetails(formating)
  }, [finalData])


  console.log('coursedetails', coursedetails)




  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (data) => {
    setActiveStep(data);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));


  // register form state 

  const [formData, setFormData] = useState(
    {
      firstname: "",
      lastname: "",
      phonenumber: "",
      email: "",
      billingaddress: "",
      billingcity: "",
      billingstate: "",
      zipcode: "",
      Dob: '',
      gender: '',
      newcountry: 'United States',
      newstate: 'Georgia'
    }
  )


  // road test question state

  const [answers, setAnswers] = useState({
    takenbtwACT: null,
    takenbtwothers: null,
    completedDE: null,
    learnerspermit: null,
    DEproofnumber: '',
    permitnumber: ''
  });



  // documents

  const [docInfo, setDocInfo] = useState([])

  console.log('docInfo', docInfo)


  const fetchDoc = async () => {
    try {
      const response = await axios.post(`${base_Url}/getDocs`, {
        "docfor": 1, // 1-student; 2- instructor; 3- car; 0-none
        "status": 1 // 0-all;1-Active;2-InActive
      })
      if (response.data.message) {
        const initialState = response?.data?.data
          .filter(document => document.req === "required")
          .map(document => ({ doctypeid: document.apptypeid, docname: document.typename, doc: null }));
        setDocInfo(initialState)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDoc()
  }, [finalData])



  // calender function 




  const [selectedCourseInfo, setSelectedCourseInfo] = useState({
    courseid: null,
    type: null,
    combo: null
  })


  console.log('selectedCourseInfo', selectedCourseInfo)



  const [zones, setZones] = useState([])

  const fetchZones = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllBranches`, {

      })
      if (response.data.status) {
        const data = response?.data?.response?.filter(item => item.locuniqid !== 5).map((item) => {
          return {
            id: item?.applocationid,
            label: item?.locationname
          }
        })
        setZones(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchZones()
  }, [])








  const onClickCalander = (item) => {
    console.log('item', item)
    // 1-combo    2 -single => based on packages length find it is combo or single 
    setSelectedCourseInfo({
      courseid: item.productid,
      type: item.producttype,
      combo: finalData?.packages.length <= 1 ? 2 : 1
    })
    setOpen(true)
  }

  const totalDuration = finalData?.duration?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);
  const totalpackageamount = finalData?.packageamount?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);

  const handleDrawerClose = () => {

    setAnswers(
      {
        takenbtwACT: null,
        takenbtwothers: null,
        completedDE: null,
        learnerspermit: null,
        DEproofnumber: '',
        permitnumber: ''
      }
    )

    setFormData(
      {
        firstname: "",
        lastname: "",
        phonenumber: "",
        email: "",
        billingaddress: "",
        billingcity: "",
        billingstate: "",
        zipcode: "",
        Dob: '',
        newcountry: 'United States',
        newstate: 'Georgia'
      }
    )

    setSelectedCourseInfo(
      {
        courseid: null,
        type: null,
        combo: null
      }
    )

    setDocInfo([])
    setCoursedetails([])
    setActiveStep(0)

    setRegisterStatus(false)
  }



  const [loaderopen, setLoaderOpen] = useState(false);


  // payment page
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '4242424242424242',
    expiryMonth: '08',
    expiryYear: '35',
    cvvNumber: '999'
  });

  const onClickSubmit = async () => {



    console.log('answers123', answers?.permitnumber)

    const imageUpload = await UploadImage({ srcData: answers?.permitnumber, folderName: `${answers?.permitnumber?.name} ` });

    console.log('imageUpload ###', imageUpload)

    setLoaderOpen(true)
    console.log('clicked submit ', coursedetails)

    const uploadedImages = await Promise.all(DigitalOcean(docInfo));
    console.log('uploadedimages', uploadedImages);



    const docs = uploadedImages.map((item) => {
      return {
        doctypeid: item?.doctypeid,
        doc: item.s3Link
      }
    })

    const de = coursedetails.map((item) => {
      return {
        "productid": item?.productid,
        "productname": item?.productname,
        "packageid": item?.packageid,
        "addonid": item?.addonid === null ? 0 : item?.addonid,  // the slot choosed
        "takenbtwACT": item?.takenbtwACT,      // 0-yes;1-no
        "takenbtwothers": item?.takenbtwothers,
        "completedDE": item?.completedDE,          // 0-yes;1-no
        "DEproofnumber": item?.DEproofnumber,
        "learnerspermit": item?.learnerspermit,       // 0-yes;1-no
        "permitnumber": !_.isNull(imageUpload) ? imageUpload : 0,
        "classmode": item?.classmode || 2,
        "locationid": item?.locationid || 0,
        "countyid": item?.countyid || 0,
        "amount": item?.packageamount,
        "comboid": item?.comboid
      }
    })


    const requestedData = {
      "licenseno": formData?.licenseno,
      "licensestate": formData?.licensestate,
      "firstname": formData.firstname,
      "lastname": formData.lastname,
      "dob": formData.Dob,
      "unitno": formData.unitNo,
      "gender": formData?.gender,
      "phone": formData.phonenumber,
      "email": formData.email,
      "parentname": formData.parentname,
      "relation": formData.relation,
      "parentemail": formData.parentemail,
      "parentphone": formData.parentphone,
      "address": formData.billingaddress,
      "country": formData?.newcountry,
      "city": formData.newcity,
      "state": formData.newstate,
      "country": formData?.newcountry,
      "zipcode": formData.zipcode,
      // "docs": docs,
      "courses": de,


      "orderdetails": {
        "totalamount": finalData?.packages?.reduce((acc, item) => acc + item.packageamount, 0) || 0,
        "discount": finalData?.discount,
        "discountamount": (finalData?.finalcost * finalData?.discount) / 100,
        "payamount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice
      },

      "paymentdetails": {
        "cardNumber": paymentDetails?.cardNumber,  // credit card
        "expirationDate": `${paymentDetails?.expiryMonth}${paymentDetails?.expiryYear}`,
        "cardCode": paymentDetails?.cvvNumber,
        "amount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice
      },
      "roleid": 2,
      "configid": 1,
      "authmode": 1,
      "registervia": 2  // 1- socialmedia; 2- website

    }


    console.log('requestedData', requestedData)
    const url = 'https://phpstack-1079878-4587506.cloudwaysapps.com/api/v1/'
    try {
      const response = await axios.post(`${url}/studentregister`, requestedData)
      if (response.data.status) {
        setLoaderOpen(false)
        toast.success('Registered Successfully')
        navigate('/checkout')
      } else {
        toast.error(response?.data?.message)
        setLoaderOpen(false)
      }
    } catch (error) {
      console.log('error in main api', error)
    }
  }

  const isRoadTest = finalData?.packages?.some((item) => item.producttype === 5);
  console.log('isRoadTest', isRoadTest)


  let steps;
  if (isRoadTest) {
    steps = [
      'Personal Details',
      'Road Test Q/A',
      'Payment',
    ];
  } else {
    steps = [
      'Personal Details',
      'Payment',
    ];
  }



  const getChipcolor = (data) => {
    console.log('item inside chip', data?.packageid)
    const colour = coursedetails?.find(item => item.packageid == data?.packageid)?.addonid
    console.log('colour', colour)
    if (!_.isEmpty(colour)) {
      return 'success'
    } else {
      return 'error'
    }

  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
      <Drawer anchor="right" open={openStatus}

        ModalProps={{ BackdropProps: { invisible: true } }}
        PaperProps={{ sx: { width: isSmallScreen ? '100%' : '80%' } }}
      >

        {/* <Dialog maxWidth='xl' fullWidth open={openStatus}  TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            height: '800px', 
            '@media (max-width: 600px)': {
              height: '800px', 
            },
            '@media (min-width: 600px) and (max-width: 960px)': {
              height: '800px',
            },
            '@media (min-width: 960px)': {
              height: '800px',
            },
          },
        }}

      > */}


        <Stack direction='row' justifyContent='space-between' sx={{ p: 1 }} alignItems={'center'}>
          <Typography variant="h5" sx={{ color: 'black !important' }}> Checkout</Typography>
          <IconButton sx={{ fontSize: '30px', color: 'red' }} variant="outlined" color='error' onClick={() => handleDrawerClose()}  >
            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <div >
                <Card sx={{ m: 1 }}>
                  <Typography sx={{ p: 1 }}>Courses</Typography>
                </Card>


                <Card sx={{ m: 1 }}>
                  {finalData?.packages?.length > 1 &&
                    <Chip icon={<BiSolidOffer />} size='small' label={'Combo Offer'} sx={{ mx: 1, mt: 1, p: 1 }} variant="filled" color="success" />
                  }
                  {finalData?.packages?.map((item, index) => (
                    <>

                      <Stack sx={{ px: 2, pt: 1, pb: 1 }} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" >

                        <Stack direction={'row'} spacing={3}>
                          <div style={{
                            // backgroundColor: 'transparent',
                            border: '1px solid #80808080',
                            borderRadius: '5px'

                          }}>

                            <img
                              src={item?.image}
                              alt='car'

                              style={{
                                objectFit: 'contain',
                                width: '90px',
                                height: '55px',
                                // padding: '3px'

                              }}
                            />
                          </div>

                          <Stack >
                            <Typography>
                              {item.productname}
                            </Typography>
                            {
                              item.producttype !== 5 &&
                              <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} spacing={0.5} >
                                <AiTwotoneClockCircle />
                                <Typography>{item.duration}Hrs</Typography>
                              </Stack>
                            }

                          </Stack>
                        </Stack>

                        <Stack spacing={1} alignItems={'center'}>
                          {
                            (item?.producttype !== 1) && (item?.producttype !== 5) ?
                              <Chip icon={<FaRegCalendarAlt />} label="Pick slots" variant="outlined" color={getChipcolor(item)}
                                sx={{ p: 0.5, cursor: 'pointer' }}
                                onClick={() => onClickCalander(item)} />

                              :
                              // <Chip icon={<FaRegCalendarAlt />} label="Pick slots" variant="outlined" color="default"
                              //   sx={{ p: 0.5, cursor: 'not-allowed', userSelect: 'none' }}
                              // />
                              null
                          }

                          {
                            (item?.producttype !== 1) && (item?.producttype !== 5) &&
                            <Typography></Typography>

                          }

                          {/* {
                            finalData?.packages.length === 1 &&
                            <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                              ${item.packageamount}
                            </span>
                          } */}

                        </Stack>


                      </Stack>

                      <Divider sx={{ mb: 0 }} />
                    </>
                  ))}
                </Card>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12} md={4}>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card  >
                      <Typography sx={{ px: 3, py: 1 }}>Order summary</Typography>
                      <Divider />
                      <Stack sx={{ p: 2 }} spacing={1}>
                        {
                          finalData?.packages?.length > 1 &&
                          <>

                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                              <Typography sx={{ color: 'gray !important' }}> sub Total</Typography>

                              <Typography>
                                <span style={{ fontWeight: "500", fontSize: "17px", color: "black" }}>
                                  ${finalData?.finalcost}
                                </span>
                              </Typography>

                            </Stack>

                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                              {/* <Typography sx={{ color: 'gray !important' }}> {` Discount (${finalData?.discount}%)`}</Typography> */}

                              <Typography sx={{ color: 'gray !important', mt: 2 }}>
                                {`Discount `}
                                {finalData?.discount && (
                                  <Chip
                                    label={`${finalData?.discount}%`}
                                    color="error"
                                    variant="combined"
                                    sx={{ ml: 1 }}
                                    size="small"
                                  />
                                )}
                              </Typography>
                              <span style={{ fontWeight: "500", fontSize: "17px", color: "black" }}>
                                ${(finalData?.finalcost * finalData?.discount) / 100}
                              </span>
                            </Stack>
                          </>
                        }
                        <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                          <Typography sx={{ color: 'gray !important' }}> Total</Typography>

                          <Typography>

                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}
                                </span> :
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${finalData?.discountprice}
                                </span>
                            }
                          </Typography>

                        </Stack>
                      </Stack>

                    </Card>
                  </Grid>
                </Grid>


              </div>
            </Grid>

            <Grid item xs={12} md={7}>
              <Box sx={{ width: '100%', mt: 0 }}>
                <Card sx={{ m: 1, p: 1.3 }}>


                  <Stepper activeStep={activeStep} orientation={isSmallScreen ? 'vertical' : 'horizontal'}>
                    {steps.map((label, index) => (
                      <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                </Card>
                {/* <Divider sx={{ mt: 1 }} /> */}



                {activeStep === 0 && (
                  <RegisterForm
                    handleNext={handleNext}
                    handleBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}
                    setActiveStep={setActiveStep}
                    coursedetails={coursedetails}

                    setRegisterStatus={setRegisterStatus}
                    finalData={finalData}


                  />
                )}

                {activeStep === 1 && (
                  <DocumentSection
                    handleNext={handleNext}
                    handleBack={handleBack}

                    answers={answers}
                    setAnswers={setAnswers}

                    coursedetails={coursedetails}
                    setCoursedetails={setCoursedetails}

                    docInfo={docInfo}
                    setDocInfo={setDocInfo}
                  />
                )}

                {activeStep === 2 && (
                  <PaymentPage
                    handleBack={handleBack}
                    onClickSubmit={onClickSubmit}
                    setActiveStep={setActiveStep}
                    coursedetails={coursedetails}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                  />

                )}
              </Box>
            </Grid>
          </Grid>




          <Calendar
            open={open}
            setOpen={setOpen}
            selectedCourseInfo={selectedCourseInfo}
            coursedetails={coursedetails}
            setCoursedetails={setCoursedetails}
            zones={zones}
          />

        </DialogContent>


        {/* </Dialog> */}

      </Drawer>
    </>
  );

};



export default NewUserRegister;